'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var generateColumnLetter = function generateColumnLetter(colIndex) {
  if (typeof colIndex !== 'number') {
    return '';
  }

  var prefix = Math.floor(colIndex / 26);
  var letter = String.fromCharCode(97 + colIndex % 26).toUpperCase();
  if (prefix === 0) {
    return letter;
  }
  return generateColumnLetter(prefix - 1) + letter;
};

exports.default = function (index, rowNumber) {
  return '' + generateColumnLetter(index) + rowNumber;
};